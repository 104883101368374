import gql from "graphql-tag";

export const GET_USER_BY_ID_QUERY = gql`
  query GetUserById($id: ID) {
    getUserById(id: $id) {
      id
    }
  }
`;

export const GET_TRANSACTIONS_BY_ENTITY_ID = gql`
  query GetTransactionsByEntityId($entityId: ID!) {
    transactions: getTransactionsByEntityId(entityId: $entityId) {
      id
      amountPaid
      date
      expired
      module {
        id
        minimumProfiles
        name
        permissions {
          id
          name
        }
        pricePerProfile
        pricePerUser
        profileInterval
        userInterval
      }
      plan {
        id
        duration
        minimumDuration
      }
      totalProfiles
      totalUsers
      type
      value
      balance
    }
  }
`;
