import gql from 'graphql-tag'
import userFragment from './userFragment'

export const SEARCH_USER_BY_EMAIL_OR_MOBILE_PHONE = gql`
  mutation SearchUserByEmailOrMobilePhone($emailOrMobilePhone: String) {
    findedAccount: searchUserByEmailOrMobilePhone(emailOrMobilePhone: $emailOrMobilePhone) {
      id
      email
      name
      photo
      telephone
    }
  }
`;

export const UPDATE_USER_PASSWORD_MUTATION = gql`
  mutation UpdateUserPassword($id: ID!, $password: String!) {
    updateUserPassword(id: $id, password: $password) {
      token
      user {
        ...${userFragment}
      }
    }
  }
`

export const SEND_VERIFY_CODE_EMAIL_MUTATION = gql`
  mutation SendVerifyCodeEmail($email: String!) {
    sendVerifyCodeEmail(email: $email)
  }
`

export const SEND_VERIFY_CODE_SMS_MUTATION = gql`
  mutation SendVerifyCodeSMS($telephone: String!) {
    sendVerifyCodeSMS(telephone: $telephone)
  }
`